import React, { useEffect, useState } from 'react';

const ScrollToTop = () => {
  const [showToTop, setShowToTop] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY > 450) {
        setShowToTop(true);
      } else {
        setShowToTop(false);
      }
    };
  }, []);

  return (
    showToTop && (
      <div className="scrollTop" onClick={() => window.scrollTo({ top: 0 })}>
        <svg
          width="20"
          height="33"
          viewBox="0 0 54 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 29L27 5.5L50.5 29"
            stroke="#fff"
            strokeWidth="7"
            strokeLinecap="round"
          />
        </svg>
      </div>
    )
  );
};

export default ScrollToTop;
