import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import SchemaOrg from './SchemaOrg';

const SEO = () => {
  const location = useLocation();

  return (
    <StaticQuery
      query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author {
              name
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }
          }
        }
      }
    `}
      render={({ site: { siteMetadata: seo } }) => {
        const title = seo.title;
        const description = seo.description;
        const image = seo.image;
        const url = location ? `${seo.siteUrl}${location.pathname}` : seo.siteUrl;

        return (
          <React.Fragment>
            <Helmet
              htmlAttributes={{
                lang: 'en'
              }}
            >
              {/* General tags */}
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="image" content={image} />
              <link rel="canonical" href={url} />

              {/* OpenGraph tags */}
              <meta property="og:url" content={url} />
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta property="og:image" content={image} />
              <meta property="fb:app_id" content={seo.social.fbAppID} />
              <meta property="og:type" content={'website'} />

              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:creator" content={seo.social.twitter} />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={image} />

              {/* marketing */}
              <meta
                name="fo-verify"
                content="a7b4fe5b-2296-42bd-9204-751555000ad0"
              />
            </Helmet>
            <SchemaOrg url={url} title={title} defaultTitle={seo.title} />
          </React.Fragment>
        );
      }}
    />
  );
};

export default SEO;
