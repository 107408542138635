import React from 'react';

import { useCookies } from 'react-cookie';

const Attribution = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    'seq_ref',
    'seq_land'
  ]);

  React.useEffect(() => {
    if (!cookies['seq_ref']) {
      if (document.referrer) {
        try {
          let referrer = document.referrer.split('/')[2];
          setCookie('seq_ref', referrer, {
            path: '/',
            domain:
              process.env.NODE_ENV === 'production'
                ? '.sequincard.com'
                : 'localhost'
          });
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (!cookies['seq_land']) {
      if (window?.location?.pathname) {
        setCookie('seq_land', window?.location?.pathname, {
          path: '/',
          domain:
            process.env.NODE_ENV === 'production'
              ? '.sequincard.com'
              : 'localhost'
        });
      }
    }
  }, []);

  return <></>;
};

export default Attribution;
