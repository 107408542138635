import React, { useState } from 'react';

import { Link } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import FlodeskModal from '../Flodesk/SignupModal';
import ScrollToTop from '../ScrollToTop';
import './index.scss';

const Footer = ({ footerData, longDisclaimer }) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="footer">
      <div className="hero__cta">
        <div className="hero__ctaContainer site-wrap flex-column d-flex flex-lg-row align-items-lg-center justify-content-lg-between">
          <div className="d-flex flex-column">
            <h2>{footerData.ctaHeader}</h2>
            <span>{footerData.ctaSubText}</span>
          </div>
          <Button
            to="/"
            className="white-button mt-4"
            onClick={() => setModalShow(true)}
          >
            {footerData.ctaButtonText}
          </Button>
          <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>
      <div className="footer-disclaimer">
        <div className="left">
          <div className="top-row">
            <div className="social-container">
              {footerData.socialLinks.map((link, index) => (
                <a key={index} href={link.url} target="_blank" rel="noreferrer">
                  <img src={link.icon} alt={link.alt} className="social-icon" />
                </a>
              ))}
            </div>
            <div className="item">
              <span className="d-flex align-items-center">
                <svg
                  width="35"
                  height="40"
                  viewBox="0 0 35 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="logo"
                >
                  <path
                    d="M34.3629 0H12.7413C4.40155 0 0 2.23938 0 9.96139C0 17.6834 4.40155 20 12.7413 20H0V40H21.6216C29.9614 40 34.3629 37.7606 34.3629 30.0386C34.3629 22.3166 29.9614 20 21.6216 20H34.3629V0Z"
                    fill="#0F1219"
                  />
                </svg>
                {footerData.madeWithLoveText}
              </span>
            </div>
          </div>
          <div className="bottom-row">{renderRichText(longDisclaimer)}</div>
        </div>
        <div className="right">
          {footerData.footerLinks.map((link, index) => {
            if (link.external) {
              return (
                <a
                  key={index}
                  href={link.link}
                  target="_blank"
                  rel="noreferrer"
                  className="purple-link"
                >
                  {link.text}
                </a>
              );
            } else {
              return (
                <Link key={index} to={link.link} className="purple-link">
                  {link.text}
                </Link>
              );
            }
          })}
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

Footer.propTypes = {
  footerData: PropTypes.object.isRequired,
  longDisclaimer: PropTypes.object.isRequired
};

export default Footer;
