import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Footer from '../Footer/index';
import HeaderNav from '../HeaderNav/index';

const Layout = ({ children, pageResources }) => {
  const data = useStaticQuery(graphql`
    query HeaderFooterQuery {
      contentfulLayoutElements {
        headerData {
          navItems {
            text
            link
            type
          }
        }
        headerLogo {
          url
          title
        }
        footerData {
          ctaHeader
          ctaSubText
          ctaButtonText
          madeWithLoveText
          footerLinks {
            link
            text
            external
          }
          socialLinks {
            alt
            url
            icon
            platform
          }
        }
        longDisclaimer {
          raw
        }
      }
    }
  `);

  const {
    headerData,
    headerLogo,
    footerData,
    longDisclaimer
  } = data.contentfulLayoutElements;

  return (
    <>
      <HeaderNav headerData={headerData} headerLogo={headerLogo} hidden={pageResources?.json?.pageContext?.hideHeaderNav} />
      {children}
      <Footer footerData={footerData} longDisclaimer={longDisclaimer} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
