import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import SignUpContext from '../../../contexts/SignUpContext';

import { Widget } from '@typeform/embed-react'

import './index.scss';
import { SEQUIN_CARD_NAME } from '../../../utils/constants';

const FlodeskModal = props => {
  const [displayFlodesk, setDisplayFlodesk] = React.useState(false);
  const context = useContext(SignUpContext);
  const [source, setSource] = React.useState(null);
  const [utmSource, setUtmSource] = React.useState(null);
  const [utmMedium, setUtmMedium] = React.useState(null);
  const [utmCampaign, setUtmCampaign] = React.useState(null);

  const [waitlistSubmitted, setWaitlistSubmitted] = React.useState(false);

  React.useEffect(() => {
    const affiliate = localStorage.getItem('affiliate');
    const from_tiktok = localStorage.getItem('from_tiktok');
    const skip_flodesk = from_tiktok || context.flodesk === false
    setSource(affiliate);


    const urlParams = new URLSearchParams(window.location.search);
    setUtmSource(urlParams.get('utm_source'));
    setUtmMedium(urlParams.get('utm_medium'));
    setUtmCampaign(urlParams.get('utm_campaign'));


    if (skip_flodesk) {
      setDisplayFlodesk(false);
      return;
    } else if (affiliate === 'hfk') {
      // window.fd('form', {
      //   formId: '64a2fcc8b915202daed171b6',
      //   containerEl: '#fd-form-64a2fcc8b915202daed171b6'
      // });
      setDisplayFlodesk(true);
      return;
    } else {
      // window.fd('form', {
      //   formId: '6329eaac2306198bf58705a9',
      //   containerEl: '#fd-form-6329eaac2306198bf58705a9'
      // });
      setDisplayFlodesk(true);
      return;
    }
  }, []);

  React.useEffect(() => {
    const from_tiktok = localStorage.getItem('from_tiktok');
    // Jul 25 2024: remove modal from the signup funnel
    const skip_flodesk = true //from_tiktok || context.flodesk === false

    if (skip_flodesk) {
      // instead of a modal, just redirect to mighty
      // to do: refactor this out of "FlodeskModal"
      track_tiktok();
      if (props.show && typeof window !== 'undefined') {
        if (window.gtag) {
          // not set in dev
          //console.log('Google Analytics event started');
          window.gtag('event', 'clicked_signup', {
            source: 'tiktok',
            event_callback: () => {
              //console.log('Google Analytics event completed');
              redirect_to_app_login();
            }
          });
        } else {
          //console.log('Google Analytics event skipped');
          redirect_to_app_login();
        }
      }
    }
  }, [props.show]);

  const redirect_to_app_login = () => {
    window.location.href = 'https://app.sequincard.com/apply';
  };

  const track_tiktok = () => {
    if (typeof window.ttq === 'object') {
      // tiktok
      window.ttq.track('ClickButton', {
        // "contents": [
        //   {
        //     "content_id": "<content_identifier>", // string. ID of the product. Example: "1077218".
        //     "content_type": "<content_type>", // string. Either product or product_group.
        //     "content_name": "<content_name>" // string. The name of the page or product. Example: "shirt".
        //   }
        // ],
        // "value": "<content_value>", // number. Value of the order or items sold. Example: 100.
        // "currency": "<content_currency>" // string. The 4217 currency code. Example: "USD".
        "contents": [
          {
            "content_id": "1", // string. ID of the product. Example: "1077218".
            "content_type": "CLICKED_SIGNUP_MODAL", // string. Either product or product_group.
            "content_name": "CLICKED_SIGNUP_MODAL" // string. The name of the page or product. Example: "shirt".
          }
        ]
      });
    }
  }

  const show = () => {
    const affiliate = localStorage.getItem('affiliate');
    window !== 'undefined' &&
      window.gtag &&
      window.gtag('event', 'clicked_signup', { source: affiliate });
    if (props.onShow) {
      props.onShow();
    }

    if (typeof window.amplitude === 'object') {
      window.amplitude.getInstance().logEvent('CLICKED_SIGNUP_MODAL');
    }

    track_tiktok();
  };

  const submitSignup = (e) => {
    e.preventDefault();

    const formData = new FormData(document.getElementById("welcome-modal-form"));

    var data = {};
    formData.forEach((value, key) => data[key] = value);

    fetch("https://track.customer.io/api/v1/forms/3ec2b0649a9a4f6/submit", {
      headers: {
        Authorization: `Basic OTY4MjY2YmUyYjNkOGEyZTkwNGM6NWRhZDQwNjAzZDBhOGIwNTUyMjE=`,
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({ data: data })
    }).then(() => {
      redirect_to_app_login();
    });

    // fetch("https://customerioforms.com/forms/submit_action?site_id=968266be2b3d8a2e904c&form_id=3ec2b0649a9a4f6", { method: "POST", body: formData }).then(() => {
    //   redirect_to_app_login();
    // });

  }

  const submitWaitlist = (e) => {
    e.preventDefault();

    const formData = new FormData(document.getElementById("welcome-modal-form"));

    var data = {};
    formData.forEach((value, key) => data[key] = value);

    fetch("https://track.customer.io/api/v1/forms/e0767ca4fb7c461/submit", {
      headers: {
        Authorization: `Basic OTY4MjY2YmUyYjNkOGEyZTkwNGM6NWRhZDQwNjAzZDBhOGIwNTUyMjE=`,
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({ data: data })
    }).then(() => {
      setWaitlistSubmitted(true);
    });
  }

  if (!displayFlodesk) {
    return <></>;
  }

  let content = (
    <Modal
      {...props}
      onShow={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* Customer.io basic */}
      {/* <form
        method="POST"
        action="https://customerioforms.com/forms/submit_action?site_id=968266be2b3d8a2e904c&form_id=3ec2b0649a9a4f6&success_url=https://app.sequincard.com/apply"
      >
        <div className="signup-panel">
          <div className="left-panel px-5 my-5">
            <img
              src="../images/manifest-it-babe.jpeg"
              className="hero-image"
              width="100%"
              height="100%"
            />
          </div>
          <div className="right-panel px-5">
            <div className="mt-5 mb-3">
              <h1>Join the Sequin <span className="highlight">Women&apos;s Finance Club</span> and Step into Financial Freedom!</h1>
            </div>
            <div className="signup-form-group mb-3">
              <input id="name_input" name="name" type="text" placeholder="First name" className="form-control mb-2" />
              <input id="email_input" name="email" type="email" placeholder="E-mail address" className="form-control mb-2" />
              <div className="checkbox-group mt-5">
                <input id="opt_in_input" name="opt_in" type="checkbox" />
                <label for="opt_in_input">Loop me in! I want the latest news and insights delivered directly</label>
              </div>
              <input type="hidden" name="source" value={source} />
              <input type="hidden" name="utm_source" value={utmSource} />
              <input type="hidden" name="utm_medium" value={utmMedium} />
              <input type="hidden" name="utm_campaign" value={utmCampaign} />
            </div>
            <button
              className="signup-form-button mb-5"
            >
              Choose Sequin Membership
            </button>
          </div>
        </div>
      </form> */}
      {/* customer.io waitlist + join */}
      <form
        id="welcome-modal-form"
      >
        <div className="signup-panel">
          <div className="left-panel px-5 my-5">
            <img
              src="../images/manifest-it-babe.jpeg"
              className="hero-image"
              width="100%"
              height="100%"
            />
          </div>
          <div className="right-panel px-5">
            <div className="mt-5 mb-3">
              <h1><span className="highlight">Create Your Sequin Account</span><br />Experience banking designed by women, for women.</h1>
            </div>
            <div className="signup-form-group mb-3">
              <input id="name_input" name="name" type="text" placeholder="First name" className="form-control mb-2" />
              <input id="email_input" name="email" type="email" placeholder="E-mail address" className="form-control mb-2" />
              <div className="checkbox-group mt-5">
                <input id="opt_in_input" name="opt_in" type="checkbox" />
                <label htmlFor="opt_in_input">Opt in to receive emails</label>
              </div>
              <input type="hidden" name="source" value={source} />
              <input type="hidden" name="utm_source" value={utmSource} />
              <input type="hidden" name="utm_medium" value={utmMedium} />
              <input type="hidden" name="utm_campaign" value={utmCampaign} />
            </div>
            <button onClick={(e) => submitSignup(e)}
              className="signup-form-button mb-5"
            >
              Get Started
            </button>
          </div>
        </div>
      </form>

      {/* typeform */}
      {/* <Widget id="P5ipMEz6" style={{ height: "70vh" }} className="my-form" /> */}

      {/* flodesk */}
      {/* <div id="fd-form-6329eaac2306198bf58705a9"></div>
      <div id="fd-form-64a2fcc8b915202daed171b6"></div> */}
    </Modal>
  );

  return <></>;
};

FlodeskModal.propTypes = {
  onHide: PropTypes.func
};

export default FlodeskModal;
